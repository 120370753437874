@import "../../assets/styles/utils";

.header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transition: all .3s;
    z-index: 100;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include res(height,$header-height-base,$header-height-ratio);
    @include res(padding-right,176px, 15 / 176);
    @include res(padding-left,50px, 15 / 50);
    &.tran{
        transform: translateY(-100%);
    }
    &.hide{
        display: none;
    }
    .logo{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            display: block;
            height: 80%;
            img{
                display: block;
                height: 100%;
                max-height: 85px;
                width: auto;
            }
        }
    }
    .header-nav{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        box-sizing: border-box;
        @include res(padding-top,25px, 0 / 25);
        .nav{
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            @include res(display,flex,(sm:none));
            @include res(font-size,18px, 14 / 18);
            a{
                display: flex;
                height: 100%;
                align-items: center;
                transition: all .3s;
                @include res(margin-right,60px,10 / 60);
                &:hover{
                    color: $color-main;
                }
                &.active{
                    span::after{
                        background-color: $color-main-yellow;
                    }
                }
                span{
                    position: relative;
                    &::after{
                        content: '';
                        display: block;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        background-color: transparent;
                        @include res(height,3px, 1 / 3);
                        @include res(bottom, -8px, -3 / -8);
                    }
                }
            }
        }
        .language{
            position: relative;
            transition: all .3s;
            padding-top: 3px;
            @include res(padding-left,7px, 5 / 7);
            @include res(padding-right,7px, 5 / 7);
            @include res(margin-right,40px, 50 / 40);
            @include res(margin-left,10px, 10 / 10);
            &:hover{
                background-color: $color-main;
                .now-lan{
                    color: #fff;
                }
                .other-lan{
                    opacity: 1;
                    visibility: visible;
                    
                }
            }
            .now-lan{
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $color-main;
                border-bottom: 1px solid rgba(255,255,255,.5);
                cursor: pointer;
                transition: all .3s;
                @include res(padding-left,12px, 8 / 12);
                .iconfont{
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include res(margin-left,10px, 5 / 10);
                    @include res(font-size,26px, 20 / 26);
                }
            }
            .other-lan{
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 100%;
                background-color: $color-main;
                opacity: 0;
                visibility: hidden;
                transition: all .3s;
                padding: 4px 0;
                @include res(padding-left,7px, 5 / 7);
                @include res(padding-right,7px, 5 / 7);
                a{
                    display: block;
                    transition: all .3s;
                    padding: 3px;
                    color: #fff;
                    @include res(padding-left,12px, 8 / 12);
                    &:hover{
                        background-color: rgba(255,255,255,.2);
                    }
                }
            }
        }
    }
}

.header-right{
    &.active{
        .global{
            opacity: 0;
            visibility: hidden;
        }
    }
    .open-menu{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 102;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        @include res(width,176px, 15 / 176);
        @include res(height,155px, 60 / 155);
        .hamburger-text{
            color: $color-main;
            text-align: center;
            @include res(font-size,14px, 12 / 14);
            @include res(margin-top,8px, 3 / 8);
            span{
                &:last-child{
                    display: none;
                }
            }
        }
        &.active{
            .hamburger-text{
                span{
                    &:first-child{
                        display: none;
                    }
                    &:last-child{
                        display: block;
                    }
                }
            }
        }
    }
    .global{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 100;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        @include res(width,176px, 15 / 176);
        @include res(height,155px, 60 / 155);
        @include res(top,155px, 60 / 155);
        a{
            text-align: center;
            &:hover{
                color: $color-main;
            }
            .iconfont{
                display: block;
                transition: all .3s;
                @include res(font-size,24px, 20 / 24);
                @include res(margin-bottom,5px, 0 / 5);
            }
            span{
                position: relative;
                transition: all .3s;
                @include res(font-size,14px, 12 / 14);
                &::after{
                    content: '';
                    display: block;
                    width: 100%;
                    background-color: $color-main-yellow;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    @include res(height,2px);
                }
            }
        }
    }
}

.mob-nav{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 101;
    background-color: $color-main;
    overflow-y: auto;
    color: rgba(255,255,255,.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    transition: all .3s;
    @include res(height,100vh);
    @include res(width,360px,(xs:100%));
    &.active{
        transform: translateX(-100%);
    }
    .menu-top{
        @include res(padding-top,70px, 20 / 70);
        @include res(padding-left,70px, 20 / 70);
        @include res(padding-right,20px, 20 / 20);
        @include res(padding-bottom,20px, 20 / 20);
        .language{
            position: relative;
            border-bottom: 1px solid rgba(255,255,255,0);
            transition: all .3s;
            @include res(width,70px);
            @include res(margin-bottom,180px, 100 / 180);
            &:hover{
                border-bottom: 1px solid rgba(255,255,255,.2);
                .other-lan{
                    opacity: 1;
                    visibility: visible;
                }
            }
            .now-lan{
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                @include res(padding-left,15px);
                .iconfont{
                    @include res(margin-left,10px, 5 / 10);
                    @include res(font-size,26px, 20 / 26);
                }
            }
            .other-lan{
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 100%;
                opacity: 0;
                visibility: hidden;
                transition: all .3s;
                padding: 5px 0;
                a{
                    display: block;
                    transition: all .3s;
                    padding: 3px;
                    padding-left: 15px;
                    &:hover{
                        background-color: rgba(255,255,255,.2);
                        color: $color-main-yellow;
                    }
                }
            }
        }
        .nav{
            a{
                display: block;
                transition: all .3s;
                @include res(margin-bottom,50px, 30 / 50);
                @include res(font-size,18px, 18 / 18);
                &:hover{
                    color: $color-main-yellow;
                }
                &.active{
                    span::after{
                        background-color: $color-main-yellow;
                    }
                }
                span{
                    position: relative;
                    &::after{
                        content: '';
                        display: block;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        background-color: transparent;
                        @include res(height,3px, 3 / 3);
                        @include res(bottom, -8px, -3 / -8);
                    }
                }
            }
        }
    }
    .menu-global{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: #108c93;
        background-color: rgba(255,255,255,.1);
        transition: all .3s;
        @include res(height,125px, 60 / 125);
        &:hover{
            // background-color: #0c848b;
            background-color: rgba(0, 0, 0, .1)
        }
        a{
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            .iconfont{
                transition: all .3s;
                @include res(font-size,24px, 20 / 24);
                @include res(margin-right,8px, 5 / 8);
            }
            span{
                font-size: 14px;
                position: relative;
                transition: all .3s;
                &::after{
                    content: '';
                    display: block;
                    width: 100%;
                    background-color: $color-main-yellow;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    @include res(height,2px);
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .header-right .open-menu{
        width: 70px;
    }
    .header-right .global{
        width: 70px;
    }
}
@media screen and (max-width: 767px) {
    .header-right .open-menu{
        width: 60px;
    }
    .header-right .global{
        width: 60px;
    }
}