.nybanner {
  display: flex;
  align-items: center;
  text-align: right;
  position: relative;
  overflow: hidden;
  background-color: #007f86;
  margin-top: 120px;
  height: 580px;
  padding-right: 176px;
}

@media (max-width: 1600px) {
  .nybanner {
    margin-top: 108px;
  }
}

@media (max-width: 1366px) {
  .nybanner {
    margin-top: 96px;
  }
}

@media (max-width: 1024px) {
  .nybanner {
    margin-top: 84px;
  }
}

@media (max-width: 991px) {
  .nybanner {
    margin-top: 72px;
  }
}

@media (max-width: 767px) {
  .nybanner {
    margin-top: 60px;
  }
}

@media (max-width: 1024px) {
  .nybanner {
    height: 400px;
  }
}

@media (max-width: 991px) {
  .nybanner {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .nybanner {
    height: 200px;
  }
}

@media (max-width: 1600px) {
  .nybanner {
    padding-right: 143.8px;
  }
}

@media (max-width: 1366px) {
  .nybanner {
    padding-right: 111.6px;
  }
}

@media (max-width: 1024px) {
  .nybanner {
    padding-right: 79.4px;
  }
}

@media (max-width: 991px) {
  .nybanner {
    padding-right: 47.2px;
  }
}

@media (max-width: 767px) {
  .nybanner {
    padding-right: 15px;
  }
}

.nybanner .nybanner-bg {
  height: 100%;
  width: 100%;
  position: relative;
}

.nybanner .nybanner-bg::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.nybanner .nybanner-bg b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.nybanner h1 {
  position: absolute;
  top: 50%;
  left: 0;
  color: #fff;
  transform: translateY(-50%);
  z-index: 2;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 88px;
  padding-left: 176px;
}

@media (max-width: 1600px) {
  .nybanner h1 {
    font-size: 76.4px;
  }
}

@media (max-width: 1366px) {
  .nybanner h1 {
    font-size: 64.8px;
  }
}

@media (max-width: 1024px) {
  .nybanner h1 {
    font-size: 53.2px;
  }
}

@media (max-width: 991px) {
  .nybanner h1 {
    font-size: 41.6px;
  }
}

@media (max-width: 767px) {
  .nybanner h1 {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .nybanner h1 {
    padding-left: 143.8px;
  }
}

@media (max-width: 1366px) {
  .nybanner h1 {
    padding-left: 111.6px;
  }
}

@media (max-width: 1024px) {
  .nybanner h1 {
    padding-left: 79.4px;
  }
}

@media (max-width: 991px) {
  .nybanner h1 {
    padding-left: 47.2px;
  }
}

@media (max-width: 767px) {
  .nybanner h1 {
    padding-left: 15px;
  }
}

@media screen and (max-width: 992px) {
  .nybanner {
    padding-right: 70px;
  }
}

@media screen and (max-width: 767px) {
  .nybanner {
    padding-right: 0px;
  }
}
