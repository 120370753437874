@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}


body {
  font-family: 'roboto', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
.comp-root{
  overflow-x: hidden;
}
//清除浮动
.clear {
  zoom: 1;
  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  width: 100%;
  box-sizing: border-box;
  @include res(padding-left, 176px, 15 / 176);
  @include res(padding-right, 176px, 15 / 176);

  &.container-1200 {
    max-width: 1200px;
  }
}

//产品列表公共样式
.product-list{
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  @include res(display,flex);
  li{
      flex-shrink: 0;
      box-sizing: border-box;
      transition: all .5s;
      background-color: #fff;
      position: relative;
      border-top: 3px solid transparent;
      @include res(padding,18px, 10 / 18);
      @include res(width,33.33%,(md:50%,xs:100%));
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background-color: transparent;
        position: absolute;
        top: -1px;
        left: 0;
        transition: all .5s;
      }
      &:hover{
        z-index: 1;
        box-shadow: 0px 0px 20px rgba(0,0,0,.2);
        &::after{
          background-color: $color-main-yellow;
        }
        .pro-cont2{
            @include res(transform,translateY(-100%),(md:translateY(0)));
        }
      }
      &:nth-child(1){
        border-left: none;
        @include res(border-top,none);
      }
      &:nth-child(2){
        @include res(border-top,none,(xs:1px solid #f1f4f7));
        @include res(border-left,1px solid #f1f4f7,(xs:none));
      }
      &:nth-child(3){
        @include res(border-top,none,(md:1px solid #f1f4f7));
        @include res(border-left,1px solid #f1f4f7,(md:none));
      }
      &:nth-child(4){
        @include res(border-top,1px solid #f1f4f7);
        @include res(border-left,none,(md:1px solid #f1f4f7,xs:none));
      }
      &:nth-child(5){
        @include res(border-top,1px solid #f1f4f7);
        @include res(border-left,1px solid #f1f4f7,(md:none));
      }
      &:nth-child(6){
        @include res(border-top,1px solid #f1f4f7);
        @include res(border-left,1px solid #f1f4f7,(xs:none));
      }
      .pro-cont{
        position: relative;
        overflow: hidden;
      }
      .pro-cont1{
        @include res(padding-top,60px, 10 / 60);
        @include res(padding-bottom,18px, 10 / 18);
        .pro-flex{
          align-items: center;
          justify-content: space-between;
          @include res(display,flex);
          @include res(margin-bottom,25px, 15 / 25);
        }
        .item-text{
          flex-shrink: 0;
          @include res(width,50%);
          .item-title1{
            color: #000;
            font-weight: bold;
            white-space: nowrap;
            @include res(font-size,50px, 24 / 50);
            @include res(margin-bottom,20px, 10 / 20);
          }
          .item-title2{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img{
              width: auto;
              max-width: 100%;
              height: auto;
              max-height: 35px;
              @include res(margin-right,20px, 10 / 20);
            }
          }
        }
        .item-img{
          overflow: hidden;
          position: relative;
          flex-shrink: 0;
          @include res(width,45%);
          @include res(height,245px,180 / 245);
          img{
            display: none;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
          }
        }
        .item-desc{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          color: #585858;
          @include res(min-height,38px, 32 / 38);
          @include res(padding-left,25px, 0 / 25);
          @include res(padding-right,25px, 0 / 25);
        }
      }
      
      .pro-cont2{
        top: 100;
        left: 0;
        width: 100%;
        z-index: 1;
        background-color: #fff;
        transition: all .5s;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        @include res(position,absolute,(md:static));
        .cont-left{
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          color: #000;
          border: 1px solid #cccccc;
          border-right: none;
          @include res(padding-top,22px, 15 / 22);
          @include res(padding-bottom,22px, 15 / 22);
          @include res(padding-left,25px, 10 / 25);
          @include res(padding-right,25px, 10 / 25);
          @include res(width,50%);
          .left-list{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .item{
              cursor: pointer;
              text-align: center;
              display: flex;
              align-items: center;
              @include res(width,44px,32 / 44);
              &::before{
                content: '';
                display: block;
                background-color: #000;
                border-radius: 50%;
                margin: 0 auto;
                transition: all .3s;
                @include res(width,10px, 6 / 10);
                @include res(height,10px, 6 / 10);
                @include res(margin-right,10px, 5 / 10);
              }
              &:not(:last-child){
                @include res(margin-right,15px);
              }
              &.active{
                &::before{
                  background-color: $color-main-yellow;
                }
              }
            }
          }
        }
        .cont-right{
          flex-shrink: 0;
          background-color: $color-main;
          transition: all .3s;
          color: #fff;
          @include res(width,50%);
          &:hover{
            a b span{
            transform: translateY(-100%);
            }
          }
          a{
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            font-family: 'lato-black';
            position: relative;
            b{
              font-weight: normal;
              display: inline-block;
              overflow: hidden;
              @include res(height,19px,16 / 19);
              span{
                display: block;
                transition: all .3s;
              }
            }
          }
        }
      }
  }
  .no-pro{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    @include res(height,500px, 200 / 500);
    .icon{
      border-radius: 50%;
      color: #fff;
      background-color: $color-main;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      @include res(width,50px, 50 / 50);
      @include res(height,50px, 50 / 50);
      .iconfont{
        @include res(font-size,30px, 30 / 30);
      }
    }
    .text{
      color: #666160;
      text-transform: uppercase;
      @include res(font-size,20px , 16 / 20);
    }
  }
}

//公共面包屑导航
.common-location{
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  @include res(font-size,14px, (lg:12px));
  @include res(padding-bottom,15px, 7 / 15);
  // @include res(margin-bottom,0,(sm:10px,xs:10px));
  @include res(margin-right,25px,0 / 25);
  span{
    display: block;
    @include res(margin-left,5px, 3 / 5);
    @include res(margin-right,5px, 3 / 5);
  }
}

//内页公共二级导航
.common-nav{
  background-color: $color-main;
  color: #fff;
  @include res(padding-top,35px, 10 / 35);
  .cont{
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row-reverse;
    white-space: nowrap;
    @include res(display,flex,(sm:block));
    .left-nav{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-x: auto;
      @include res(font-size, 20px, 14 / 20);
      a{
        border-bottom-style: solid;
        border-bottom-color: transparent;
        @include res(padding-top,0,(sm:10px));
        @include res(padding-bottom,12px, 5 / 12);
        @include res(border-bottom-width,3px, 2 / 3);
        &:not(:last-child){
          @include res(margin-right,30px, 10 / 30)
        }
        &:hover{
          font-weight: bold;
        }
        &.active{
          font-weight: bold;
          border-bottom-color: $color-main-yellow;
        }
      }
    }
  }
}

.ny-common-title{
  font-weight: bold;
  color: $color-main;
  line-height: 1.2;
  @include res(font-size,40px, 20 / 40);
  @include res(padding-top,90px, 20 / 90);
  @include res(padding-bottom,50px, 15 / 50);
}

.page{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  @include res(font-size,24px, 16 / 24);
  @include res(margin-top,90px, 30 / 90);
  // @include res(padding-bottom,150px, 50 / 150);
  a{
    display: block;
    transition: all .3s;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    color: $color-main;
    @include res(border-bottom-width,4px, 2 / 4);
    @include res(margin-left,15px, 8 / 15);
    @include res(margin-right,15px, 8 / 15);
    @include res(padding-left,5px, 3 / 5);
    @include res(padding-right,5px, 3 / 5);
    @include res(padding-bottom,3px, 2 / 3);
    &:hover{
      color: $color-main-yellow;
    }
    &.active{
      color: $color-main;
      border-bottom-color: $color-main-yellow;
    }
  }
}

.ny-pd-btm{
  @include res(padding-bottom,150px, 30 / 150);
}