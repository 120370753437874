@import "../../assets/styles/utils";

.footer-top{
    position: relative;
    background-color: $color-main;
    border-top: solid $color-main-yellow;
    color: #fff;
    @include res(padding-top,60px, 20 / 60);
    @include res(border-top-width,4px, 2 / 4);
    @include res(padding-bottom,0px, (xs:20px));
    .container{
        align-items: flex-start;
        justify-content: space-between;
        @include res(display,flex,(xs:block));
        .left-img{
            flex-shrink: 0;
            overflow: hidden;
            max-width: 228px;
            @include res(margin-left,40px, 0 / 40);
            @include res(display,block,(xs:none));
            @include res(width,18.94%,(xs:100%));
            @include res(height,280px, 100 / 280);
            img{
                width: 100%;
                height: auto;
            }
        }
        .right-text{
            flex-shrink: 0;
            align-items: flex-start;
            justify-content: space-between;
            @include res(width,67.6%,(sm:74%,xs:100%));
            @include res(display,flex,(xs:block));
            .info-title{
                color: #fff;
                text-transform: uppercase;
                @include res(font-size,28px, 18 / 28);
                @include res(margin-bottom,25px, 10 / 25);
                @include res(text-align,null,(xs:center));
            }
            .contact{
                flex-shrink: 0;
                @include res(text-align,null,(xs:center));
                @include res(margin-right,15px);
                @include res(max-width,450px,(sm:380px,xs:100%));
                p{
                    @include res(line-height,1.875, 1.6 / 1.875);
                }
            }
            .link{
                flex-shrink: 0;
                @include res(text-align,null,(xs:center));
                @include res(margin-top,0,(xs:20px));
                @include res(margin-right,240px, 0 / 240);
                .cont{
                    .iconfont{
                        @include res(font-size,42px, 30 / 42);
                        @include res(margin-right,10px, 5 / 10);
                    }
                }
            }
        }
    }
    .back-top{
        background-color: #acacac;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s;
        position: absolute;
        top: 0;
        z-index: 2;
        transform: translateY(-50%);
        @include res(right,100px, 20 / 100);
        @include res(width,74px, 50 / 74);
        @include res(height,74px, 50 / 74);
        &:hover{
            margin-top: -10px;
        }
        .iconfont{
            transform: rotate(-90deg);
            @include res(font-size,24px, 20 / 24);
        }
    }
}

.footer-copyright{
    background-color: #525252;
    .container{
        color: #fff;
        align-items: center;
        justify-content: space-between;
        @include res(text-align,null,(xs:center));
        @include res(display,flex,(xs:block));
        @include res(padding-top,35px, 10 / 35);
        @include res(padding-bottom,35px, 10 / 35);
        .left{
            position: relative;
            z-index: 1;
        }
        .right{
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            @include res(justify-content,flex-end,(xs:center));
            a{
                transition: all .3s;
                &:hover{
                    color: $color-main;
                }
            }
            .site-map{
                @include res(display,null,(md:none));
            }
            span{
                display: inline-block;
                @include res(margin-left,5px, 3 / 5);
                @include res(margin-right,5px, 3 / 5);
            }
        }
    }
}